var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { viewBox: "0 0 46.1 46.1", width: "1em", height: "1em" } },
    [
      _c("path", {
        attrs: {
          fill: "currentColor",
          d:
            "M42.2,23.1c0-3.9-1.1-7.4-3.1-10.4l7-7L40.5,0l-7,7c-3-2-6.6-3.1-10.4-3.1S15.6,5.1,12.6,7l-7-7L0,5.6l7,7 c-2,3-3.1,6.6-3.1,10.4S5.1,30.5,7,33.5l-7,7l5.6,5.6l7-7c3,2,6.6,3.1,10.4,3.1s7.4-1.1,10.4-3.1l7,7l5.6-5.6l-7-7 C41.1,30.5,42.2,26.9,42.2,23.1z M35.5,23.1c0,2-0.5,3.9-1.3,5.5l-5.5-5.5l5.5-5.5C35,19.2,35.5,21.1,35.5,23.1z M23.1,10.6 c2,0,3.9,0.5,5.5,1.3l-5.5,5.5l-5.5-5.5C19.2,11.1,21.1,10.6,23.1,10.6z M10.6,23.1c0-2,0.5-3.9,1.3-5.5l5.5,5.5l-5.5,5.5 C11.1,26.9,10.6,25,10.6,23.1z M23.1,35.5c-2,0-3.9-0.5-5.5-1.3l5.5-5.5l5.5,5.5C26.9,35,25,35.5,23.1,35.5z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }