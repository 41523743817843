var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { viewBox: "0 0 49.5 46.1", width: "1em", height: "1em" } },
    [
      _c("polygon", {
        attrs: {
          fill: "currentColor",
          points:
            "39.4,33 43.4,15 32.3,24.4 24.8,0 17.3,24.4 6.2,15 10.1,33 0,28.9 7.1,46.1 16.1,46.1 13.3,38.9  18,40.6 16.1,34.1 21.7,36.7 24.8,25.7 27.8,36.7 33.4,34.1 31.5,40.6 36.2,38.9 33.4,46.1 42.4,46.1 49.5,28.9 "
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }