var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "match" },
    [
      _vm.$scopedSlots.teams || (_vm.teams && _vm.teams.length)
        ? _c(
            "div",
            { staticClass: "teams" },
            [
              _vm._t(
                "teams",
                function() {
                  return [
                    _vm._l(_vm.teams, function(team, index) {
                      return _vm._t(
                        "team",
                        function() {
                          return [
                            _c(
                              "TeamCell",
                              _vm._b(
                                {
                                  key: index,
                                  staticClass: "team",
                                  attrs: {
                                    link: _vm.teamLinks,
                                    variant: "row-reverse",
                                    size: "sm"
                                  }
                                },
                                "TeamCell",
                                team,
                                false
                              )
                            )
                          ]
                        },
                        { team: team, index: index }
                      )
                    })
                  ]
                },
                { teams: _vm.teams }
              )
            ],
            2
          )
        : _vm._e(),
      _c(
        "V3ScrollableContainer",
        { staticClass: "rounds", attrs: { horizontal: "" } },
        [
          _vm._t(
            "rounds",
            function() {
              return [
                _vm._l(_vm.rounds, function(round, index) {
                  return _vm._t(
                    "round",
                    function() {
                      return [
                        _c(
                          "MatchRoundSelectorItem",
                          _vm._b(
                            {
                              key: index,
                              attrs: {
                                disabled: _vm.disabled && _vm.disabled[index],
                                error: _vm.errored && _vm.errored[index],
                                highlighted:
                                  _vm.highlighted && _vm.highlighted[index],
                                selected: _vm.selected && _vm.selected[index],
                                "has-note": _vm.notes && _vm.notes[index]
                              },
                              nativeOn: {
                                click: function($event) {
                                  return function($event) {
                                    return _vm.onClick($event, round, index)
                                  }.apply(null, arguments)
                                }
                              }
                            },
                            "MatchRoundSelectorItem",
                            round,
                            false
                          )
                        )
                      ]
                    },
                    {
                      disabled: _vm.disabled && _vm.disabled[index],
                      error: _vm.errored && _vm.errored[index],
                      index: index,
                      highlighted: _vm.highlighted && _vm.highlighted[index],
                      selected: _vm.selected && _vm.selected[index],
                      round: round,
                      hasNote: _vm.notes && _vm.notes[index],
                      onClick: function($event) {
                        return _vm.onClick($event, round, index)
                      }
                    }
                  )
                })
              ]
            },
            { rounds: _vm.rounds }
          ),
          _vm.rounds.length > 12
            ? _c(
                "div",
                {
                  staticClass: "first-half-end",
                  on: {
                    click: function($event) {
                      return _vm.$emit("switch-role")
                    }
                  }
                },
                [_c("IconArrowRepeat")],
                1
              )
            : _vm._e(),
          _vm.rounds.length > 24
            ? _c(
                "div",
                {
                  staticClass: "second-half-end",
                  on: {
                    click: function($event) {
                      return _vm.$emit("switch-role")
                    }
                  }
                },
                [_c("IconArrowRepeat")],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm.$scopedSlots.scores || (_vm.scores && _vm.scores.length)
        ? _c(
            "div",
            { staticClass: "scores" },
            [
              _vm._t(
                "scores",
                function() {
                  return [
                    _vm._l(_vm.scores, function(score, index) {
                      return _vm._t(
                        "score",
                        function() {
                          return [
                            _c("div", { key: index, staticClass: "score" }, [
                              _vm._v(" " + _vm._s(score) + " ")
                            ])
                          ]
                        },
                        { score: score, index: index }
                      )
                    })
                  ]
                },
                { scores: _vm.scores }
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }