var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._b(
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.noninteractive",
            modifiers: { hover: true, noninteractive: true }
          }
        ],
        tag: "div",
        staticClass: "round",
        class: [
          _vm.winner.role,
          _vm.winner.grid,
          {
            disabled: _vm.disabled,
            error: _vm.error,
            highlighted: _vm.highlighted,
            selected: _vm.selected
          }
        ],
        attrs: {
          role: "checkbox",
          title: _vm.errorMessage,
          "aria-checked": _vm.selected ? "true" : "false"
        }
      },
      "div",
      _vm.$attrs,
      false
    ),
    [
      _c("div", { staticClass: "number" }, [
        _vm._v(" " + _vm._s(_vm.number) + " "),
        _vm.hasNote
          ? _c("span", { staticClass: "note" }, [
              _c("sup", [_c("b-icon-card-text")], 1)
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "winner", class: [_vm.winner.role, _vm.winner.grid] },
        [_c(_vm.icon, { tag: "component" })],
        1
      ),
      _c("div", {
        staticClass: "loser",
        class: [_vm.loser.role, _vm.loser.grid]
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }